// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cegeknek-js": () => import("./../../../src/pages/cegeknek.js" /* webpackChunkName: "component---src-pages-cegeknek-js" */),
  "component---src-pages-csaladoknak-js": () => import("./../../../src/pages/csaladoknak.js" /* webpackChunkName: "component---src-pages-csaladoknak-js" */),
  "component---src-pages-eskuvo-js": () => import("./../../../src/pages/eskuvo.js" /* webpackChunkName: "component---src-pages-eskuvo-js" */),
  "component---src-pages-form-js": () => import("./../../../src/pages/form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-koszonjuk-js": () => import("./../../../src/pages/koszonjuk.js" /* webpackChunkName: "component---src-pages-koszonjuk-js" */),
  "component---src-pages-lovaglas-js": () => import("./../../../src/pages/lovaglas.js" /* webpackChunkName: "component---src-pages-lovaglas-js" */),
  "component---src-pages-sajattaboraink-js": () => import("./../../../src/pages/sajattaboraink.js" /* webpackChunkName: "component---src-pages-sajattaboraink-js" */),
  "component---src-pages-szallasok-js": () => import("./../../../src/pages/szallasok.js" /* webpackChunkName: "component---src-pages-szallasok-js" */),
  "component---src-pages-szervezznalunktaborokat-js": () => import("./../../../src/pages/szervezznalunktaborokat.js" /* webpackChunkName: "component---src-pages-szervezznalunktaborokat-js" */),
  "component---src-pages-szolgaltatasok-js": () => import("./../../../src/pages/szolgaltatasok.js" /* webpackChunkName: "component---src-pages-szolgaltatasok-js" */),
  "component---src-pages-szuletesnapok-js": () => import("./../../../src/pages/szuletesnapok.js" /* webpackChunkName: "component---src-pages-szuletesnapok-js" */)
}

